.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  justify-content: space-around;
}

.container:hover {
  cursor: pointer;
}

.thumbnailContainer {
  background-size: contain;
  flex: 1;
  position: relative;
  border-radius: 8px 8px 0 0;
  margin: 22px 10px 0px;
}

.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuIcon {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  color: #dee1eb;
  align-items: center;
  padding: 11px 12px 0 8px;
  width: fit-content;
}

.menuIcon>svg:hover {
  color: #1973FF;
  cursor: pointer;
}

.boardName {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.213px;
}

.details {
  border-top: 1px solid #e6e9ef;
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
}

.details.editing {
  background-color: #f6f9fc;
}

.lastModified {
  font-family: Poppins, Roboto, Arial;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #797e93;
  margin: 0;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.canvasMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;
  border: 1px solid #ebedf3;
  border-radius: 6px;
  background: #fff;
  padding: 10px;
}

.item {
  width: 100%;
  padding: 6px;
  color: #11355e;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;
  line-height: 25.612px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.item:hover {
  cursor: pointer;
  background: #e6f1ff;
  border-radius: 4px;
}

.subtitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.link {
  cursor: pointer;
}

.link::before {
  content: "";
  position: absolute;
}