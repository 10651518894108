.modalContainer {
    background: white;
    width: 623px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    cursor: pointer;
    align-self: flex-end;
    color: #848199;
    padding: 10px 17px;
}

.contentWrapper {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.title {
    color: #113357;
    text-align: center;
    font-family: Sora;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 29.809px;
    letter-spacing: -0.098px;
}

.description {
    color: #113357;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.129px;
}

.buttonContainer {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}